import React from 'react';
//@ts-ignore
import * as styles from './customHeadline.module.css';
import { graphql } from 'gatsby';
//@ts-ignore
import {
    KontentStringValue,
    KontentRadioMultiChoice,
    BrandColor,
} from '../../../../types/KontentProps';
import { getColor, getMultiChoice } from '../../Global/DataUtils';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type HeaderTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadingStyleType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadlineAlignmentType = 'right' | 'left' | 'center' | undefined;

interface Props {
    component_anchor_name?: KontentStringValue;
    tag?: KontentRadioMultiChoice<HeaderTagType>;
    style: HeadingStyleType;
    headline_value: KontentStringValue;
    color?: BrandColor;
    overrideStyles?: React.CSSProperties;
    alignment?: KontentRadioMultiChoice<HeadlineAlignmentType>;
}

const CustomHeadline: React.FC<Props> = (props) => {
    const breakpoints = useBreakpoint();
    let { tag, style, headline_value, color, alignment, overrideStyles } =
        props;
    const textColor = getColor(color);
    const HeaderTag = typeof tag != 'string' ? getMultiChoice(tag) : tag;
    const StyleTag = style;

    const headlineAlignment = getMultiChoice(alignment);
    const headlineAlignmentCase = headlineAlignment
        ? headlineAlignment.toLowerCase()
        : null;
    const MobileHeadlineTopSpacing =
        !breakpoints.l &&
        (StyleTag === 'h1' || StyleTag === 'h2' || StyleTag === 'h3');

    const astrikStyles = `${
        StyleTag === 'h2'
            ? '1.5rem'
            : StyleTag === 'h4'
            ? '1rem'
            : StyleTag === 'h5'
            ? '0.75rem'
            : StyleTag === 'h6'
            ? '0.75rem'
            : '1.5rem'
    }`;
    const spacingTop = `${
        MobileHeadlineTopSpacing
            ? '0px'
            : StyleTag === 'h3'
            ? '0.5rem'
            : StyleTag === 'h2'
            ? '0.5rem'
            : StyleTag === 'h1'
            ? '1.5rem'
            : '0rem'
    }`;

    let headlineAlignPosition = setInnerContentPosition(headlineAlignmentCase);
    function htmlHeader(header: any) {
        switch (header) {
            case 'h1':
                return 'h1';
                break;
            case 'h2':
                return 'h2';
                break;
            case 'h3':
                return 'h3';
                break;
            case 'h4':
                return 'h4';
                break;
            case 'h5':
                return 'h5';
                break;
            case 'h6':
                return 'h6';
                break;
            default:
                return 'div';
        }
    }

    const componentStyles = overrideStyles
        ? { ...headlineAlignPosition, ...overrideStyles }
        : headlineAlignPosition;

    return (
        <>
            {!headline_value?.value.includes('\n')
                ? React.createElement(
                      htmlHeader(HeaderTag),
                      {
                          style: {
                              color: textColor,
                              style: StyleTag,
                              ...componentStyles,
                          },
                          className:
                              styles[StyleTag] + ' ' + styles.headlineStyle,
                          id: props.component_anchor_name?.value,
                      },
                      <>
                          {headline_value?.value?.endsWith('*') ? (
                              <>
                                  {headline_value?.value?.slice(0, -1)}
                                  <span
                                      style={{
                                          position: 'relative',
                                          fontSize: astrikStyles,
                                          top: spacingTop,
                                          verticalAlign: 'top',
                                      }}
                                  >
                                      *
                                  </span>
                              </>
                          ) : (
                              headline_value?.value
                          )}
                      </>
                  )
                : headline_value?.value.split('\n').map((text, index) => {
                      return React.createElement(
                          htmlHeader(HeaderTag),
                          {
                              style: {
                                  color: textColor,
                                  style: StyleTag,
                                  ...componentStyles,
                              },
                              className:
                                  styles[StyleTag] + ' ' + styles.headlineStyle,
                              id: props?.component_anchor_name?.value
                                  ? props?.component_anchor_name?.value + index
                                  : index,
                          },
                          <>
                              {text?.endsWith('*') ? (
                                  <>
                                      {text?.slice(0, -1)}
                                      <span
                                          style={{
                                              position: 'relative',
                                              fontSize: astrikStyles,
                                              top: spacingTop,
                                              verticalAlign: 'top',
                                          }}
                                      >
                                          *
                                      </span>
                                  </>
                              ) : (
                                  text
                              )}
                          </>
                      );
                  })}
        </>
    );
};

export default CustomHeadline;

function setInnerContentPosition(headlineAlignmentCase: HeadlineAlignmentType) {
    if (headlineAlignmentCase == 'left') {
        return {
            textAlign: 'left',
        } as React.CSSProperties;
    }

    if (headlineAlignmentCase == 'right') {
        return {
            textAlign: 'right',
        } as React.CSSProperties;
    }
    if (headlineAlignmentCase == 'center') {
        return {
            textAlign: 'center',
        } as React.CSSProperties;
    }

    return {};
}
